import React from 'react';

export default function Hero() {
  return (
    <section className="">
      <div className="hero-body">
        <div className="container">
          <img src="https://cepro-webapp-dev-s3-static.s3.amazonaws.com/logos/slogan_large_white.PNG" alt="CEPRO Slogan" />
        </div>
      </div>
    </section>
  )
}
