import React, { Component } from 'react'

export default class Welcome extends Component {

  render() {
    return (
      <section className="section auth">
        <div className="container">
          <h1>Welcome!</h1>
          <p>Let's get you started.</p>
        </div>
      </section>
    )
  }
}