import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import ForgotPassword from './components/auth/ForgotPassword';
import ForgotPasswordVerification from './components/auth/ForgotPasswordVerification';
import ChangePassword from './components/auth/ChangePassword';
import ChangePasswordConfirm from './components/auth/ChangePasswordConfirm';
import { Auth } from 'aws-amplify';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import CeproSampleScreen from './components/screens/CeproSampleScreen';
import CeproNavbarHeader from './components/border/CeproNavbarHeader';
import CeproNavbarMenu from './components/border/CeproNavbarMenu';
import CeproNavbarFooter from './components/border/CeproNavbarFooter';
import Welcome from './components/screens/CeproWelcome';
import Thanks from './components/screens/CeproThanks';

library.add(faEdit);

class App extends Component {

  state = {
    isAuthenticated: false,
    isAuthenticating: true,
    user: null,
    cognitoHostedUiUrl: "https://login.cloudexampro.com/login?client_id=ifgtc0adsc9p9u92q865l59co&response_type=code&scope=email+openid&redirect_uri=https%3A%2F%2Fcloudexampro.com%2Fwelcome",
    // cognitoHostedUiUrl: "https://login.cloudexampro.com/login?client_id=ifgtc0adsc9p9u92q865l59co&response_type=code&scope=email+openid&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fwelcome",
  }

  /* SET STATE */
  setAuthStatus = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  setUser = user => {
    this.setState({ user: user });
  }

  setIsAuthenticating = authenticating => {
    this.setState({ isAuthenticating: authenticating});
  }

  /* COMPONENT DID MOUNT */
  async componentDidMount() {
    try {
      const session = await Auth.currentSession();
      console.log(session);
      this.setAuthStatus(true);
      console.log("Set Auth: true");
      const user = await Auth.currentAuthenticatedUser();
      this.setUser(user);
      console.log(user);
    } catch (error) {
      console.log(error);
    }
    this.setIsAuthenticating(false);
  }

  render() {
    const authProps = {
      isAuthenticated: this.state.isAuthenticated,
      user: this.state.user,
      cognitoHostedUiUrl: this.state.cognitoHostedUiUrl,
      setAuthStatus: this.setAuthStatus,
      setUser: this.setUser
    }
    return (
      !this.state.isAuthenticating &&
      <div className="App">
        <Router>
          <div>
            <CeproNavbarHeader auth={authProps}/>

            <div>
              <table>
                <tbody>
                  <tr>
                    <td className="primary_color">
                      <CeproNavbarMenu auth={authProps}/>
                    </td>
                    <td>
                      <Switch>
                        <Route exact path="/" render={(props) => <Home {...props} auth={authProps} />} />
                        <Route exact path="/login" render={(props) => <Login {...props} auth={authProps} />} />
                        <Route exact path="/sign-up" render={(props) => <Register {...props} auth={authProps} />} />
                        <Route exact path="/welcome" render={(props) => <Welcome {...props} auth={authProps} />} />
                        <Route exact path="/thanks" render={(props) => <Thanks {...props} auth={authProps} />} />
                        <Route exact path="/dashboard" render={(props) => <CeproSampleScreen {...props} auth={authProps} />} />

                        <Route exact path="/forgot-password" render={(props) => <ForgotPassword {...props} auth={authProps} />} />
                        <Route exact path="/forgot-password-verification" render={(props) => <ForgotPasswordVerification {...props} auth={authProps} />} />
                        <Route exact path="/change-password" render={(props) => <ChangePassword {...props} auth={authProps} />} />
                        <Route exact path="/change-password-confirmation" render={(props) => <ChangePasswordConfirm {...props} auth={authProps} />} />
                      </Switch>
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>

            <CeproNavbarFooter />
          </div>
        </Router>
      </div>
    );
  }
}

export default App;