import React from 'react';

export default function Thanks() {
  return (
    <section className="section auth">
      <div className="container">
        <h1>Thanks for using the site!</h1>
        <p>We value your support. Come back soon!</p>
      </div>
    </section>
  )
}