import React, { Component } from 'react'
import { Auth } from 'aws-amplify';

export default class CeproNavbarHeader extends Component {


  handleLogout = async event => {
    event.preventDefault();
    try {
      await Auth.signOut();
      this.props.auth.setAuthStatus(false);
      this.props.auth.setUser(null);
      console.log(event);
      window.location.href = "/thanks";
    } catch (error) {
      console.log(error.message);
    }
  }

  render() {
    return (

      <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-item s-center justify-content-center">
          <a className="navbar-brand brand-logo" href="/">
            <img src="https://cepro-webapp-dev-s3-static.s3.amazonaws.com/logos/main_xlarge_white_1-border.png" alt="cepro_homebutton"/>
          </a>
          <a className="navbar-brand brand-logo-mini" href="/">
            <img src="https://cepro-webapp-dev-s3-static.s3.amazonaws.com/icons/transparent.png" alt="cepro_homebutton_mini"/>
          </a>
        </div>

        <div className="navbar-menu-wrapper d-flex align-item s-center justify-content-end">

          { /* Minimize Button */ }
          <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
            <span className="icon-menu"></span>
          </button>
          
          { /* 'Navigate to ...' Search Bar */ }
          <ul className="navbar-nav mr-lg-2">
            <li className="nav-item nav-search d-none d-lg-block">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="search">
                    <i className="icon-search"></i>
                  </span>
                </div>
                <input type="text" className="form-control" placeholder="Navigate to ..." aria-label="search" aria-describedby="search"/>
              </div>
            </li>
          </ul>
          <ul className="navbar-nav navbar-nav-right">

            { /* Points Button */ }
            <li className="nav-item dropdown d-lg-flex d-none">
  
              { /* LOGGED OUT - Rewards Hub */ }
              {!this.props.auth.isAuthenticated && (
                <a href="/rewards-hub">
                  <button type="button" className="btn btn-info font-weight-bold">Rewards Hub</button>
                </a>
              )}

              { /* LOGGED IN - Wallet */ }
              {this.props.auth.isAuthenticated && this.props.auth.user && (
                <a href="/wallet">
                  <button type="button" className="btn btn-info font-weight-bold">+ 25 Pts</button>
                </a>
              )}

            </li>

            { /* Choose Cloud */ }
            <li className="nav-item dropdown d-flex mr-4 ">
              <a href="/" className="nav-link count-indicator dropdown-toggle d-flex align-item s-center justify-content-center" id="notificationDropdown" data-toggle="dropdown">
                <i className="icon-cloud-download"></i>
              </a>
              <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">

                { /* Logged Out Settings */ }
                {!this.props.auth.isAuthenticated && (
                  <div>
                    <p className="mb-0 font-weight-normal float-left dropdown-header">Choose Platform</p>
                    <a href="/aws" className="dropdown-item preview-item">
                      <i className="icon-monitor"></i> AWS
                    </a>
                    <a href="/gcp" className="dropdown-item preview-item">
                      <i className="icon-server"></i> GCP
                    </a>
                    <a href="/azure" className="dropdown-item preview-item">
                      <i className="icon-globe"></i> Azure
                    </a>
                  </div>
                )}

                { /* Logged In Settings */ }
                {this.props.auth.isAuthenticated && this.props.auth.user && (
                  <div>
                    <p className="mb-0 font-weight-normal float-left dropdown-header">Choose Exam</p>
                    <a href="/dashboard" className="dropdown-item preview-item">               
                      <i className="icon-paper"></i> AWS SA-Pro
                    </a>
                    <a href="/exams" className="dropdown-item preview-item">
                      {/* <i className="icon-square-plus"></i> Add Exam */}
                      <i className="icon-marquee-plus"></i> Add Exam
                      {/* <i className="icon-file-add"></i> Add Exam */}
                    </a>
                  </div>
                )}

              </div>
            </li>

            { /* LOGGED OUT TOP-NAVBAR OPTIONS */ }
            {!this.props.auth.isAuthenticated && (
              <div>
                {/* <li className="nav-item dropdown mr-4 d-lg-flex d-none"> */}
                <li className="nav-item dropdown d-lg-flex d-none">
                  
                  <div>
                    {/* <a href="/sign-up">
                      <button type="button" className="btn btn-info custom_header_btn custom_signup_btn">
                        <i className="icon-head"></i>
                        Sign up
                        </button>
                    </a> */}
                    <a href="/sign-up" className="nav-link count-indicatord-flex align-item s-center justify-content-center custom_header_btn">
                      <i className="icon-head"></i> <span className="custom_header_btn_text">Sign Up</span>
                    </a>
                  </div>
                  <div>
                    <a href="/login">
                      {/* <button type="button" className="btn btn-info custom_header_btn custom_login_btn">
                        <i className="icon-inbox"></i>
                        Log in
                      </button> */}
                    </a>
                    <a href="/login" className="nav-link count-indicatord-flex align-item s-center justify-content-center custom_header_btn">
                      <i className="icon-inbox"></i> <span className="custom_header_btn_text">Login</span>
                    </a>
                  </div>

                </li>
              </div>
            )}

            { /* LOGGED IN TOP-NAVBAR OPTIONS */ }
            {this.props.auth.isAuthenticated && this.props.auth.user && (
              <div className="d-flex">

                { /* Notifications */ }
                <li className="nav-item dropdown d-flex">
                  <a className="nav-link count-indicator dropdown-toggle d-flex justify-content-center align-item s-center" id="messageDropdown" href="/" data-toggle="dropdown">
                    <i className="icon-air-play"></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="messageDropdown">
                    <p className="mb-0 font-weight-normal float-left dropdown-header">Notifications</p>

                    <div>

                      <a className="dropdown-item preview-item" href="/daily-question">
                        <div className="preview-thumbnail">
                            <i className="icon-clock"></i>
                        </div>
                        <div className="preview-item-content flex-grow">
                          <h6 className="preview-subject ellipsis font-weight-normal">+ Extra Points!
                          </h6>
                          <p className="font-weight-light small-text text-muted mb-0">
                            The Daily Question is ready!
                          </p>
                        </div>
                      </a>

                      <a className="dropdown-item preview-item" href="/messages">
                        <div className="preview-thumbnail">
                          <i className="icon-mail"></i>
                        </div>
                        <div className="preview-item-content flex-grow">
                          <h6 className="preview-subject ellipsis font-weight-normal"><strong>[MSG]</strong> From the owner
                          </h6>
                          <p className="font-weight-light small-text text-muted mb-0">
                            My thank you message -
                          </p>
                        </div>
                      </a>

                      <a className="dropdown-item preview-item" href="/messages">
                        <div className="preview-thumbnail">
                            <i className="icon-mail"></i>
                        </div>
                        <div className="preview-item-content flex-grow">
                          <h6 className="preview-subject ellipsis font-weight-normal"><strong>[MSG]</strong> Welcome to CEPRO!
                          </h6>
                          <p className="font-weight-light small-text text-muted mb-0">
                            Your startup guide here:
                          </p>
                        </div>
                      </a>
                    
                    </div>

                  </div>
                </li>

                { /* Apps */ }
                <li className="nav-item dropdown mr-4 d-lg-flex d-none">
                  <a className="nav-link count-indicator dropdown-toggle d-flex align-item s-center justify-content-center" id="notificationDropdown" href="/" data-toggle="dropdown">
                    <i className="icon-grid"></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
                    <p className="mb-0 font-weight-normal float-left dropdown-header">Apps</p>

                    <div>
                      <a className="dropdown-item preview-item" href="/practice">               
                        <i className="icon-monitor"></i> Resume Training
                      </a>
                      <a className="dropdown-item preview-item" href="/daily-question">
                        <i className="icon-arrow-right"></i> Daily Question
                      </a>
                      <a className="dropdown-item preview-item" href="/progress-skills">
                        <i className="icon-pie-graph"></i> Progress & Skills
                      </a>
                    </div>

                  </div>
                </li>

                { /* Settings */ }
                <li className="nav-item dropdown d-flex mr-4 ">
                  <a href="/" className="nav-link count-indicator dropdown-toggle d-flex align-item s-center justify-content-center" id="notificationDropdown" data-toggle="dropdown">
                    <i className="icon-cog"></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
                    <p className="mb-0 font-weight-normal float-left dropdown-header">Settings</p>

                    

                    { /* Logged In Settings */ }
                    {/* {this.props.auth.isAuthenticated && this.props.auth.user && ( */}
                    <div>
                      <a href="/account" className="dropdown-item preview-item">               
                        <i className="icon-head"></i> Account
                      </a>
                      <a href="/thanks" onClick={this.handleLogout} className="dropdown-item preview-item">
                        <i className="icon-inbox"></i> Logout
                      </a>
                    </div>

                  </div>
                </li>

              </div>

            )}



          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
            <span className="icon-menu"></span>
          </button>
        </div>

      </nav>

    )
  }
}
