import React, { Component } from 'react'

export default class CeproNavbarMenu extends Component {

    render() {
        return (
            <div className="custom_navbar_menu">
                <nav className="sidebar sidebar-offcanvas" id="sidebar">
                    <div className="user-profile">

                    {!this.props.auth.isAuthenticated && (
                        <div>
                            <div className="user-image">
                                <img src="https://cepro-webapp-dev-s3-static.s3.amazonaws.com/icons/default_profile_picture.PNG" alt="profile-pic"/>
                            </div>
                            <div className="user-name">
                                <strong>Welcome</strong>
                            </div>
                            <div className="user-designation">
                                Cloud Exam Pro
                            </div>
                            <div className="user-designation">
                                <i>Free Cloud Prep</i>
                            </div>
                        </div>
                    )}
                    
                    {this.props.auth.isAuthenticated && this.props.auth.user && (
                        <div>
                            <div className="user-image">
                                <img src="https://cepro-webapp-dev-s3-static.s3.amazonaws.com/icons/default_profile_picture.PNG" alt="profile-pic"/>
                            </div>
                            <div className="user-name">
                                    New User
                            </div>
                            {/* {this.props.auth.user.attributes.email && (
                                <div className="user-name">
                                    { this.props.auth.user.attributes.email }
                                </div>
                            )} */}
                            {/* {this.props.auth.user.signInUserSession.idToken.payload.email && (
                                <div className="user-name">
                                    { this.props.auth.user.signInUserSession.idToken.payload.email }
                                </div>
                            )} */}
                            <div className="user-level">
                                Level 1.0
                            </div>
                            <div className="user-designation">
                                Cloud Exam Practitioner
                            </div>
                        </div>
                    )}

                    </div>
                    <ul className="nav">

                        { /* DASHBOARD */ }
                        <li className="nav-item">
                            <a className="nav-link" href="/dashboard">
                                <i className="icon-box menu-icon"></i>
                                <span className="menu-title">Dashboard</span>
                            </a>
                        </li>

                        { /* FREE QUESTIONS */ }
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
                                <i className="icon-disc menu-icon"></i>
                                <span className="menu-title">Free Questions</span>
                                <i className="menu-arrow"></i>
                            </a>

                            { /* Free Questions - Dropdown */ }
                            <div className="collapse" id="ui-basic">
                                <ul className="nav flex-column sub-menu">
                                    {/* <li className="nav-item"> <a className="nav-link" href="/aws">Warm Up</a></li> */}
                                    <li className="nav-item"> <a className="nav-link" href="/practice">Practice Now</a></li>
                                    <li className="nav-item"> <a className="nav-link" href="/extras">Extra Material</a></li>
                                </ul>
                            </div>
                        </li>

                        { /* YOUR CLOUD PATH */ }
                        <li className="nav-item">
                            <a className="nav-link" href="/your-cloud-path">
                            <i className="icon-command menu-icon"></i>
                            <span className="menu-title">Your Cloud Path</span>
                            </a>
                        </li>

                        { /* PROGRESS & SKILLS */ }
                        <li className="nav-item">
                            <a className="nav-link" href="/progress-skills">
                            <i className="icon-pie-graph menu-icon"></i>
                            {/* <i className="icon-bar-graph-2 menu-icon"></i> */}
                            <span className="menu-title">Progress & Skills</span>
                            </a>
                        </li>

                        { /* CLOUD PATH LEADERS */ }
                        <li className="nav-item">
                            <a className="nav-link" href="/leaderboard">
                            <i className="icon-help menu-icon"></i>
                            <span className="menu-title">Cloud Path Leaders</span>
                            </a>
                        </li>

                        { /* ACCOUNT */ }
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="collapse" href="#auth" aria-expanded="false" aria-controls="auth">
                            <i className="icon-head menu-icon"></i>
                            <span className="menu-title">Account</span>
                            <i className="menu-arrow"></i>
                            </a>
                            <div className="collapse" id="auth">
                                <ul className="nav flex-column sub-menu">
                                    <li className="nav-item"> <a className="nav-link" href="pages/samples/login.html"> Profile </a></li>
                                    <li className="nav-item"> <a className="nav-link" href="pages/samples/register.html"> Register </a></li>
                                </ul>
                            </div>
                        </li>

                        { /* PRACTICE EXAMS */ }
                        <li className="nav-item">
                            <a className="nav-link" href="/exams">
                            <i className="icon-book menu-icon"></i>
                            <span className="menu-title">Practice Exams</span>
                            <i className="menu-arrow"></i>
                            </a>
                        </li>

                        { /* REQUEST A SAMPLE */ }
                        <li className="nav-item">
                            <a className="nav-link" href="/request-sample">
                            <i className="icon-file menu-icon"></i>
                            <span className="menu-title">Request a Sample</span>
                            </a>
                        </li>

                        { /* ABOUT US */ }
                        {/* <li className="nav-item">
                            <a className="nav-link" href="/about-us">
                            <i className="icon-file menu-icon"></i>
                            <span className="menu-title">About Us</span>
                            </a>
                        </li> */}

                        { /* DONATE */ }
                        {/* <li className="nav-item">
                            <a className="nav-link" href="/donate">
                            <i className="icon-file menu-icon"></i>
                            <span className="menu-title">Donate :)</span>
                            </a>
                        </li> */}

                    </ul>
                </nav>

            </div>
        )
    }
}
