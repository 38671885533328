import React, { Component } from 'react';
import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import { Auth } from "aws-amplify";

class Login extends Component {
  state = {
    username: "",
    password: "",
    errors: {
      cognito: null,
      blankfield: false
    }
  };

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false
      }
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    // AWS Cognito integration here
    const { username, password } = this.state;

    try {
      const user = await Auth.signIn({
        username,
        password
      });
      console.log(user);
      this.props.auth.setAuthStatus(true);
      this.props.auth.setUser(user);
      this.props.history.push("/welcome");
    } catch (error) {
      let err = null;
      !error.message ? err = { "message": error } : err = error;
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err
        }
      });
    }
  };

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  render() {
    return (
      <section className="section auth">
        <div className="container">
          <h1>Log in</h1>
          <FormErrors formerrors={this.state.errors} />

          <form onSubmit={this.handleSubmit}>
            <div className="field">
              <p className="control">
                <input 
                  className="input" 
                  type="text"
                  id="username"
                  aria-describedby="usernameHelp"
                  placeholder="Enter username or email"
                  value={this.state.username}
                  onChange={this.onInputChange}
                />
              </p>
            </div>
            <div className="field">
              <p className="control has-icons-left">
                <input 
                  className="input" 
                  type="password"
                  id="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.onInputChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-lock"></i>
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control">
                <a href="/forgotpassword">Forgot password?</a>
              </p>
            </div>
            <div className="field">
              <p className="control">
                <button className="button is-success">
                  Login
                </button>
              </p>
            </div>
          </form>
          <br/>
          <div className="buttons">
            <a href={this.props.auth.cognitoHostedUiUrl} className="button is-primary">
              <strong>Continue with Google, Facebook, Amazon</strong>
            </a>
          </div>
          <div className="buttons">
            {/* <a href="https://login.cloudexampro.com/login?client_id=ifgtc0adsc9p9u92q865l59co&response_type=code&scope=email+openid&redirect_uri=https%3A%2F%2Fwww.cloudexampro.com%2Fwelcome" className="button is-primary">
              <strong>Sign in with Google, Facebook, Amazon</strong>
            </a>
          </div>
          <div>
            <a href="https://www.facebook.com/login.php?skip_api_login=1&api_key=1375145049743584&kid_directed_site=0&app_id=1375145049743584&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fv17.0%2Fdialog%2Foauth%3Fclient_id%3D1375145049743584%26redirect_uri%3Dhttps%253A%252F%252Flogin.cloudexampro.com%252Foauth2%252Fidpresponse%26scope%3Demail%26response_type%3Dcode%26state%3DH4sIAAAAAAAAAGWQX2-bMBTFv4uf4wSDjW0eR7tKG3-SpiPJpgnZxhCCiUmA0nbad5_3OO3pnqPzu7pH9xcQIALzCLUYJ4jKJA2SfRjnIVgB6ZLPQmlpbeescratm0l5ohoVH_jM_RsLiSFcWZdXLj9P0zBGm82yLGtl7FzpN9EPd7tWtt8s2rihHaodqmz1V9b_HmlA9APoXrTGGTvoa1uBnyvQOYp8gn0Cz993zRTLs-7KYAlS-Bq3X_13KmPUNtmMs_sT5WWRXztWGu5f6LDc_Zft4ZDF8fYyfpG5SPNyV347HAr2jgO8PDy3e47NMfUux-QmTgGJkwwyv9gzhpcPSx935ExPL-npsT4Ktpdb79m_KBK-Nf3TLe3qOdv2EhVUzTuUFw9TgbDrblxjY5v2-t8fXNiDCIU8RCSkHlmBAUS1MKNegZvbekV07Tno7rSvqSB1xSFlQkHMGYaS1gQiLqSHcMVrj4LffwBsRZvbxQEAAA.H4sIAAAAAAAAANvg8-pwlW2Nc8lq9dmi2_29JZcodjVfnTX5_Mdk2xmL8tUAOngjdCAAAAA.4%26ret%3Dlogin%26fbapp_pres%3D0%26logger_id%3D8c80d0ef-c858-49ef-b2bb-0ec243f9d0c7%26tp%3Dunspecified&cancel_url=https%3A%2F%2Flogin.cloudexampro.com%2Foauth2%2Fidpresponse%3Ferror%3Daccess_denied%26error_code%3D200%26error_description%3DPermissions%2Berror%26error_reason%3Duser_denied%26state%3DH4sIAAAAAAAAAGWQX2-bMBTFv4uf4wSDjW0eR7tKG3-SpiPJpgnZxhCCiUmA0nbad5_3OO3pnqPzu7pH9xcQIALzCLUYJ4jKJA2SfRjnIVgB6ZLPQmlpbeescratm0l5ohoVH_jM_RsLiSFcWZdXLj9P0zBGm82yLGtl7FzpN9EPd7tWtt8s2rihHaodqmz1V9b_HmlA9APoXrTGGTvoa1uBnyvQOYp8gn0Cz993zRTLs-7KYAlS-Bq3X_13KmPUNtmMs_sT5WWRXztWGu5f6LDc_Zft4ZDF8fYyfpG5SPNyV347HAr2jgO8PDy3e47NMfUux-QmTgGJkwwyv9gzhpcPSx935ExPL-npsT4Ktpdb79m_KBK-Nf3TLe3qOdv2EhVUzTuUFw9TgbDrblxjY5v2-t8fXNiDCIU8RCSkHlmBAUS1MKNegZvbekV07Tno7rSvqSB1xSFlQkHMGYaS1gQiLqSHcMVrj4LffwBsRZvbxQEAAA.H4sIAAAAAAAAANvg8-pwlW2Nc8lq9dmi2_29JZcodjVfnTX5_Mdk2xmL8tUAOngjdCAAAAA.4%23_%3D_&display=page&locale=en_US&pl_dbl=0">
              Continue with Google
            </a>
            <a href="https://www.facebook.com/login.php?skip_api_login=1&api_key=1375145049743584&kid_directed_site=0&app_id=1375145049743584&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fv17.0%2Fdialog%2Foauth%3Fclient_id%3D1375145049743584%26redirect_uri%3Dhttps%253A%252F%252Flogin.cloudexampro.com%252Foauth2%252Fidpresponse%26scope%3Demail%26response_type%3Dcode%26state%3DH4sIAAAAAAAAAGWQX2-bMBTFv4uf4wSDjW0eR7tKG3-SpiPJpgnZxhCCiUmA0nbad5_3OO3pnqPzu7pH9xcQIALzCLUYJ4jKJA2SfRjnIVgB6ZLPQmlpbeescratm0l5ohoVH_jM_RsLiSFcWZdXLj9P0zBGm82yLGtl7FzpN9EPd7tWtt8s2rihHaodqmz1V9b_HmlA9APoXrTGGTvoa1uBnyvQOYp8gn0Cz993zRTLs-7KYAlS-Bq3X_13KmPUNtmMs_sT5WWRXztWGu5f6LDc_Zft4ZDF8fYyfpG5SPNyV347HAr2jgO8PDy3e47NMfUux-QmTgGJkwwyv9gzhpcPSx935ExPL-npsT4Ktpdb79m_KBK-Nf3TLe3qOdv2EhVUzTuUFw9TgbDrblxjY5v2-t8fXNiDCIU8RCSkHlmBAUS1MKNegZvbekV07Tno7rSvqSB1xSFlQkHMGYaS1gQiLqSHcMVrj4LffwBsRZvbxQEAAA.H4sIAAAAAAAAANvg8-pwlW2Nc8lq9dmi2_29JZcodjVfnTX5_Mdk2xmL8tUAOngjdCAAAAA.4%26ret%3Dlogin%26fbapp_pres%3D0%26logger_id%3D8c80d0ef-c858-49ef-b2bb-0ec243f9d0c7%26tp%3Dunspecified&cancel_url=https%3A%2F%2Flogin.cloudexampro.com%2Foauth2%2Fidpresponse%3Ferror%3Daccess_denied%26error_code%3D200%26error_description%3DPermissions%2Berror%26error_reason%3Duser_denied%26state%3DH4sIAAAAAAAAAGWQX2-bMBTFv4uf4wSDjW0eR7tKG3-SpiPJpgnZxhCCiUmA0nbad5_3OO3pnqPzu7pH9xcQIALzCLUYJ4jKJA2SfRjnIVgB6ZLPQmlpbeescratm0l5ohoVH_jM_RsLiSFcWZdXLj9P0zBGm82yLGtl7FzpN9EPd7tWtt8s2rihHaodqmz1V9b_HmlA9APoXrTGGTvoa1uBnyvQOYp8gn0Cz993zRTLs-7KYAlS-Bq3X_13KmPUNtmMs_sT5WWRXztWGu5f6LDc_Zft4ZDF8fYyfpG5SPNyV347HAr2jgO8PDy3e47NMfUux-QmTgGJkwwyv9gzhpcPSx935ExPL-npsT4Ktpdb79m_KBK-Nf3TLe3qOdv2EhVUzTuUFw9TgbDrblxjY5v2-t8fXNiDCIU8RCSkHlmBAUS1MKNegZvbekV07Tno7rSvqSB1xSFlQkHMGYaS1gQiLqSHcMVrj4LffwBsRZvbxQEAAA.H4sIAAAAAAAAANvg8-pwlW2Nc8lq9dmi2_29JZcodjVfnTX5_Mdk2xmL8tUAOngjdCAAAAA.4%23_%3D_&display=page&locale=en_US&pl_dbl=0">
              <strong>Continue with Facebook</strong>
            </a>
            <a href="https://na.account.amazon.com/ap/signin?_encoding=UTF8&openid.mode=checkid_setup&openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.pape.max_auth_age=0&ie=UTF8&openid.ns.pape=http%3A%2F%2Fspecs.openid.net%2Fextensions%2Fpape%2F1.0&openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&pageId=lwa&openid.assoc_handle=amzn_lwa_na&marketPlaceId=ATVPDKIKX0DER&arb=c1d4e8e4-8cb8-49fe-ba9f-e4318b666dd4&language=en_US&openid.return_to=https%3A%2F%2Fna.account.amazon.com%2Fap%2Foa%3FmarketPlaceId%3DATVPDKIKX0DER%26arb%3Dc1d4e8e4-8cb8-49fe-ba9f-e4318b666dd4%26language%3Den_US&enableGlobalAccountCreation=1&metricIdentifier=amzn1.application.b8ffd1de7dd2487bbb58fd11213104b9&signedMetricIdentifier=muk8pf1pHdOlGELKe9fctq%2FinbIjpGCMQpLfE5CxiBA%3D">
              <strong>Continue with Amazon</strong>
            </a> */}
          </div>
        </div>
      </section>
    );
  }
}

export default Login;